import { useRouteLoaderData } from '@remix-run/react'
import classNames from 'classnames'
import { useEffect, useState } from 'react'

import { Website_Info } from '~/website.models'

export function DraftList({
  closeHandler,
  opened,
  width,
}: {
  closeHandler: () => void
  opened: boolean
  width: string
}) {
  const data = useRouteLoaderData<Website_Info>('root')

  const [selectedTab, setSelectedTab] = useState(data?.taplist[0]?.id)

  useEffect(() => {
    const polyfillScript = document.createElement('script')
    polyfillScript.src = `https://files${data?.env === 'prod' ? '' : '.dev'}.bevwerk.com/polyfills.js`
    polyfillScript.type = 'module'
    polyfillScript.defer = true
    document.body.appendChild(polyfillScript)

    const menuScript = document.createElement('script')
    menuScript.src = `https://files${data?.env === 'prod' ? '' : '.dev'}.bevwerk.com/website-menu.js`
    menuScript.type = 'module'
    menuScript.defer = true
    document.body.appendChild(menuScript)

    return () => {
      document.body.removeChild(menuScript)
      document.body.removeChild(polyfillScript)
    }
  })

  return (
    <>
      <div
        className={classNames(`modal`, {
          opened: opened,
        })}
        style={{
          width: `${String(width)}`,
          overflowY: 'scroll',
        }}
      >
        <button
          className="modal-close"
          onClick={closeHandler}
          title="close draft list"
        >
          <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
            <polygon points="445.2,109.2 402.8,66.8 256,213.6 109.2,66.8 66.8,109.2 213.6,256 66.8,402.8 109.2,445.2 256,298.4 402.8,445.2 445.2,402.8 298.4,256" />
          </svg>
        </button>

        <div className="draft-list">
          {(data?.taplist?.length ?? 0) > 1 &&
            (data?.taplist ?? []).map(taplist => (
              <button
                className={`draft-list-tab ${taplist.id === selectedTab ? 'active' : ''}`}
                key={taplist.id}
                onClick={() => setSelectedTab(taplist.id)}
              >
                {taplist.title}
              </button>
            ))}

          {(data?.taplist ?? []).map(taplist => (
            <div
              key={taplist.id}
              className={`draft-list-menu ${taplist.id === selectedTab ? 'active' : ''}`}
            >
              <bw-website-menu-root
                taplist-id={taplist.id}
              ></bw-website-menu-root>
            </div>
          ))}
        </div>
      </div>

      <div
        role="presentation"
        className={classNames('overlay', {
          active: opened,
        })}
        onClick={closeHandler}
      ></div>
    </>
  )
}
