import { LoaderFunctionArgs, MetaFunction } from '@remix-run/node'
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useRevalidator,
  useRouteError,
  useRouteLoaderData,
} from '@remix-run/react'
import * as Sentry from '@sentry/react'
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix'
import slugify from '@sindresorhus/slugify'
import { useEffect, useState } from 'react'

import { DraftList } from './components/draft-list'
import { Footer } from './components/footer'
import { Header } from './components/header'
import { getBranding } from './utils/get-branding'
import { WebsiteInfoLoader } from './utils/website-info.loader'
import { Website_Info } from './website.models'

import '~/styles/root.scss'

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      // This is the Angular BevWerk website menu component
      'bw-website-menu-root': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement> & {
          'taplist-id': string
        },
        HTMLElement
      >
    }
  }
}

export const loader = async ({ request }: LoaderFunctionArgs) =>
  WebsiteInfoLoader({ request })

export const meta: MetaFunction<typeof loader> = ({
  data,
  params,
  location,
}) => {
  if (!data) {
    return []
  }

  const url = `${data?.origin}${location.pathname === '/' ? '' : location.pathname}`

  const pageParam = params.page ?? ''

  const page =
    data.pages?.find(
      (page: { title: string }) => slugify(page.title) === (pageParam ?? ''),
    ) || data.pages?.find((page: { type: string }) => page.type === 'home')

  const { config } = data
  const settings = config?.[0] ?? {}
  const { twitter_handle } = settings

  const title = (page?.meta_title || settings?.default_meta_title) ?? ''
  const description =
    (page?.meta_description || settings?.default_meta_description) ?? ''

  const { logoUrl } = getBranding(data)

  return [
    {
      name: 'description',
      content: description,
    },
    {
      property: 'og:title',
      content: title,
    },
    {
      property: 'og:description',
      content: description,
    },
    {
      property: 'og:image',
      content: logoUrl,
    },
    {
      property: 'og:url',
      content: url,
    },
    {
      property: 'og:site_name',
      content: title,
    },
    {
      name: 'twitter:title',
      content: title,
    },
    {
      name: 'twitter:description',
      content: description,
    },
    {
      name: 'twitter:image',
      content: logoUrl,
    },
    {
      name: 'twitter:creator',
      content: twitter_handle,
    },
    {
      name: 'twitter:site',
      content: twitter_handle,
    },
    {
      title,
    },
  ]
}

function Document({
  children,
  title,
}: {
  children: React.ReactNode
  title?: string
}) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {title ? <title>{title}</title> : null}
        <Meta />
        <Links />
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  )
}

function App() {
  const [menu, setMenu] = useState(false)
  const data = useRouteLoaderData<Website_Info>('root')

  const { revalidate } = useRevalidator()

  useEffect(() => {
    const id = setInterval(revalidate, 1000 * 60 * 10) // refresh data every to 10 minutes

    return () => clearInterval(id)
  }, [revalidate])

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement
    if (!link) {
      link = document.createElement('link')
      link.rel = 'icon'
      document.getElementsByTagName('head')[0].appendChild(link)
    }
    link.href = data?.faviconUrl ?? link.href
  }, [data?.faviconUrl])

  const showMenu = () => {
    const html = document.querySelector('html')

    setMenu(true)

    Sentry.metrics.increment('draftist_button_click', 1, {
      tags: {
        env: data?.env ?? 'unknown',
        hostname: data?.hostname ?? 'unknown',
        location_id: data?.location_id ?? 'unknown',
        title: data?.config[0].default_title ?? 'unknown',
      },
    })

    if (html) {
      html.classList.add('no-scroll')
    }
  }

  const hideMenu = () => {
    const html = document.querySelector('html')

    setMenu(false)

    if (html) {
      html.classList.remove('no-scroll')
    }
  }

  return (
    <Document>
      {data?.location_id ? (
        <link rel="stylesheet" type="text/css" href={data.stylesheetUrl} />
      ) : null}

      <Header />

      <Outlet context={{ ...data, showMenu }} />

      <Footer />

      <DraftList opened={menu} closeHandler={hideMenu} width="700px" />

      <button className="btn-dl" onClick={showMenu} aria-label="Draft List">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90.91 108">
          <g>
            <path d="m78.68,41.77c6.75,0,12.23,5.48,12.23,12.23h0v25.52c0,6.75-5.48,12.23-12.23,12.23h-11.39v9.7c0,3.59-2.95,6.54-6.54,6.54H11.81c-3.59,0-6.54-2.95-6.54-6.54v-62.23c-2.95-2.53-5.27-7.8-5.27-11.6,0-8.23,6.75-14.98,14.98-14.98h1.05c2.11-4.64,7.59-8.44,12.66-8.44,1.05,0,2.53.21,3.59.42,2.74-2.53,8.23-4.64,12.02-4.64,7.38,0,14.98,5.7,16.88,12.87,6.75,1.27,12.45,7.8,12.45,14.77,0,4.22-2.95,9.7-6.33,12.23v1.9h11.39Zm-20.25-7.38v.42c3.8,0,6.75-2.95,6.75-6.75s-2.95-6.75-6.75-6.75h-.84c-2.11,0-4.01-1.9-4.22-4.01h0c0-5.06-3.8-9.07-8.86-9.07-2.74,0-6.12,1.69-7.59,4.01-.63.84-2.11,1.9-3.38,1.9-.63,0-1.69-.42-2.11-.63-.63-.42-1.9-.84-2.74-.84-2.95,0-5.27,2.32-5.48,5.27-.21,2.11-2.11,3.8-4.22,3.8-.42,0-1.05,0-1.48-.21-.63-.21-1.9-.63-2.53-.63-3.8,0-6.75,2.95-6.75,6.75,0,2.11,1.48,4.64,3.16,5.7v14.98c0,2.32,1.9,4.22,4.22,4.22s4.22-1.9,4.22-4.22v-13.92h38.6Zm21.3,45.14l-.21-25.52c0-.42-.42-.84-.84-.84h-11.39v27.21h11.6c.42,0,.84-.42.84-.84Z" />
          </g>
        </svg>
      </button>
    </Document>
  )
}

export default withSentry(App)

export function ErrorBoundary() {
  const data = useRouteLoaderData<Website_Info>('root')

  const error = useRouteError()

  console.error(error)

  captureRemixErrorBoundaryError(error)

  let errorMessage = ''

  if (isRouteErrorResponse(error)) {
    errorMessage = `${error.status} ${error.statusText}`
  } else {
    errorMessage = error instanceof Error ? error.message : 'Unknown error'
  }

  return (
    <Document title={errorMessage}>
      {data ? (
        <>
          <Header />
          <div className="page-content">
            <div className="container">
              <h1>{errorMessage}</h1>

              <p>
                Ladies and gentlemen, let me tell you, we have the best
                websites, the best servers, tremendous, believe me. But
                sometimes, even with all our greatness, you hit a 404 error.
                Now, I&apos;ll tell you, it&apos;s not our fault, okay?
                It&apos;s those nasty little gremlins, probably Democrats,
                trying to sabotage us. But don&apos;t worry, we&apos;re gonna
                fix it, and we&apos;re gonna make the internet great again! So
                just hang tight, we&apos;ll have this all sorted out in no time.
                Thank you, thank you very much!
              </p>
            </div>
          </div>
          <Footer />
        </>
      ) : (
        <>
          <div className="page-content">
            <div className="container">
              <h1>{errorMessage}</h1>
            </div>
          </div>
        </>
      )}
    </Document>
  )
}
