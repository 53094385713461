import { NavLink, useRouteLoaderData } from '@remix-run/react'
import slugify from '@sindresorhus/slugify'
import { useEffect, useState } from 'react'

import { Logo } from '~/components/logo'
import { getBranding } from '~/utils/get-branding'
import { prefetchImage } from '~/utils/prefetch-image'
import { Website_Info, Website_Page } from '~/website.models'

function NavLinks({ pages }: { pages: Website_Page[] }) {
  return pages.map(page => {
    return (
      <NavLink
        key={page.id}
        className="header-nav__item"
        to={`/${page.type === 'home' ? '' : slugify(page.title)}`}
        prefetch="render"
        onMouseEnter={prefetchImage.bind(null, page.hero_images?.[0]?.url)}
        onFocus={prefetchImage.bind(null, page.hero_images?.[0]?.url)}
      >
        {page.title}
      </NavLink>
    )
  })
}

export function Header() {
  const data = useRouteLoaderData<Website_Info>('root')

  const pages = data?.pages ?? []
  const _pages = pages?.filter(page => page.enabled) ?? []
  const logoUrl = getBranding(data)?.logoUrl ?? ''

  const [scrolled, setScrolled] = useState(false)

  const toggleMobileNav = () => {
    const mobileNav = document.querySelector('.mobile-nav')

    if (mobileNav) {
      mobileNav.classList.toggle('expanded')
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScrolled(window.scrollY > 15)
    })
  }, [])

  return (
    <header className={scrolled ? 'header scrolled' : 'header'}>
      <div className="container">
        {logoUrl ? <Logo url={logoUrl} size="large" /> : null}

        <nav className="header-nav">
          <NavLinks pages={_pages}></NavLinks>
        </nav>

        <nav className="mobile-nav">
          <button
            className="mobile-nav-btn"
            onClick={toggleMobileNav}
            title="mobile menu"
          >
            <svg
              viewBox="0 0 28 28"
              xmlns="http://www.w3.org/2000/svg"
              className="icon-menu"
            >
              <g>
                <path d="M4 7C4 6.44771 4.44772 6 5 6H24C24.5523 6 25 6.44771 25 7C25 7.55229 24.5523 8 24 8H5C4.44772 8 4 7.55229 4 7Z"></path>
                <path d="M4 13.9998C4 13.4475 4.44772 12.9997 5 12.9997L16 13C16.5523 13 17 13.4477 17 14C17 14.5523 16.5523 15 16 15L5 14.9998C4.44772 14.9998 4 14.552 4 13.9998Z"></path>
                <path d="M5 19.9998C4.44772 19.9998 4 20.4475 4 20.9998C4 21.552 4.44772 21.9997 5 21.9997H22C22.5523 21.9997 23 21.552 23 20.9998C23 20.4475 22.5523 19.9998 22 19.9998H5Z"></path>
              </g>
            </svg>

            <svg
              className="icon-close"
              viewBox="0 0 512 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <polygon points="445.2,109.2 402.8,66.8 256,213.6 109.2,66.8 66.8,109.2 213.6,256 66.8,402.8 109.2,445.2 256,298.4 402.8,445.2 445.2,402.8 298.4,256" />
            </svg>
          </button>

          <div className="mobile-nav-wrapper">
            <NavLinks pages={_pages}></NavLinks>
          </div>
        </nav>
      </div>
    </header>
  )
}
